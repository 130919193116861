import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import warning from "../../resources/icons/red-warning.svg";
import BlueWarning from "../../resources/icons/blue-warning.svg";
import { useState } from "react";
import Toast from "../Toast/Toast";

export default function VerificationApprovalModal({
  isModalOpen, // isModalOpen will represent the action type (accept or reject)
  toggleModal,
  actionType, // Accept or Reject
  handleConfirmAction,
  transaction_id,
  setRejectionReason,
}) {
  const [rejectionComment, setRejectionComment] = useState("");
  function closeModal() {
    toggleModal(null);
  }
  return (
    <>
      <Transition appear show={isModalOpen ? true : false} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25 backdrop-blur-md" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-sm transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all relative">
                  <button
                    type="button"
                    className="absolute top-3 right-3 text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={closeModal}
                  >
                    <span className="sr-only">Close</span>
                    &#10005;
                  </button>
                  <Dialog.Title
                    as="h3"
                    className="text-lg flex justify-center font-medium leading-6 text-gray-900"
                  >
                    <img
                      src={
                        actionType === "Valid"
                          ? BlueWarning
                          : actionType === "Rejected"
                          ? warning
                          : warning
                      }
                      alt="warning"
                    />
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-lg font-medium flex justify-center">
                      {actionType === "Valid" ? "Approve" : "Reject"}
                    </p>
                    <p className="text-sm text-gray-500 mt-2 flex justify-center">
                      {actionType === "Valid"
                        ? "Are you sure you want to approve the request?"
                        : "Are you sure you want to reject the request?"}
                    </p>
                    {actionType === "Rejected" && (
                <div className="mt-3">
                  <label
                    htmlFor="rejectionComment"
                    className="block text-sm font-medium text-gray-700 mb-0.5"
                  >
                    Please provide a reason for rejection:
                  </label>
                  <textarea
                    id="rejectionComment"
                    value={rejectionComment}
                    onChange={(e) => {setRejectionComment(e.target.value); setRejectionReason(e.target.value); }}
                    className="w-full px-2 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
                    rows={3}
                    placeholder="Enter rejection reason..."
                    style={{ fontSize: '0.875rem' }}
                    required
                  />
                </div>
              )}
                  </div>
                  <div className="mt-4 flex justify-center gap-4">
                  {/* <div className={`mt-${actionType === "Rejected" ? "6" : "4"} flex justify-center gap-4`}> */}
                    <button
                      data-modal-hide="popup-modal"
                      type="button"
                      onClick={closeModal}
                      className="py-2.5 px-5 ms-3 text-sm hover:bg-gray-200 font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 focus:z-10 focus:ring-3 focus:ring-gray-100"
                    >
                      Cancel
                    </button>
                    {/* <button
                      data-modal-hide="popup-modal"
                      type="button"
                      onClick={() => {
                        console.log("Reject Button Clicked");  // Debug log
                        console.log("Passing to handleConfirmAction:", rejectionComment);
                        handleConfirmAction(actionType, transaction_id,  rejectionComment.trim() // Only pass comment for Reject
                      );
                  }} */}

                      <button
                        data-modal-hide="popup-modal"
                        type="button"
                        onClick={async () => {
                          console.log("Reject Button Clicked");  // Debug log
                          console.log("Passing to handleConfirmAction:", rejectionComment);

                          try {
                            await handleConfirmAction(
                              actionType,
                              transaction_id,
                              rejectionComment.trim() // Only pass comment for Reject
                            );

                            // Show success toast
                            Toast({
                              type: "success",
                              title: "Transaction Updated",
                              message: `Transaction has been ${
                                actionType === "Valid" ? "approved" : "rejected"
                              } successfully!`,
                            });
                          } catch (error) {
                            console.error("Error during confirmation:", error);

                            // Show error toast
                            Toast({
                              type: "error",
                              title: "Error",
                              message: "An error occurred while updating the transaction status.",
                            });
                          }
                      }}
                      className={`${
                        actionType === "Valid"
                          ? "bg-[#3538CD] hover:bg-[#3538CD]" // Accept button colors
                          : !rejectionComment.trim()
                          ? "bg-red-300 cursor-not-allowed opacity-70" 
                          : "bg-[#D94D40] hover:bg-[#D94D40]" // Reject button colors
                      } text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center`}
                      disabled={actionType === "Rejected" && !rejectionComment.trim()}
                    >
                      {/* Different button text based on actionType */}
                      {actionType === "Valid" ? "Approve" : "Reject"}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
