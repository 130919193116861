import React, { useContext, useEffect, useRef, useState } from "react";
import Navbar from "../components/Navbar/Navbar";
import { Outlet, useLocation } from "react-router-dom";
import AuthContext from "../store/auth-context";
import Sidebar from "../components/Sidebar/Sidebar";
import usePageBottom from "../hooks/usePageBottom";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from '../store/auth-context'; 

const AuthLayout = () => {
  const authContext = useContext(AuthContext);
  const customElementRef = useRef(null);
  const reachedBottom = usePageBottom(
    { rootMargin: 0, threshold: 0.5 },
    customElementRef
  );
  const location = useLocation();
  const navigate = useNavigate();


  useEffect(() => {
    authContext.hasDiscoveryReachedBottomHandler(reachedBottom);
  }, [reachedBottom]);


  useEffect(() => {
    if (!authContext.isLoggedIn) {
      // Save the current path to redirect after login only if it's an issuer route
      if (location.pathname.startsWith("/issuer/")) {
        localStorage.setItem("redirectAfterLogin", location.pathname);
        navigate("/");
      }
    }
  }, [authContext.isLoggedIn, location.pathname, navigate]);

  if (!authContext.isLoggedIn) {
    return null; 
  }

  return (
    <React.Fragment>
      <div className="fixed top-0 w-full z-10">
        <Navbar />
      </div>
      <div className="overflow-y-hidden">
        <aside
          className="fixed top-[5.1rem] left-0 w-fit h-screen z-10"
          aria-label="Sidebar"
        >
          <div className="h-full overflow-y-auto">
            <Sidebar />
          </div>
        </aside>
        <div className="mb-[4rem] sm:!mb-[0rem] ml-0 sm:!ml-[5.7rem] overflow-auto">
          {authContext.isLoggedIn && <Outlet />}
        </div>
      </div>
    </React.Fragment>
  );
};

export default AuthLayout;
