import React, { useContext, useState, useEffect, useCallback } from "react";
import AuthContext from "../../store/auth-context";
import EntityConstants from "../../configs/EntityConstants";
import { POST, PUT } from "../../service/CRUDService";
import { GET } from "../../service/CRUDService";
import Toast from "../../common/Toast/Toast";
import Dropdown from "../../common/Dropdown/Dropdown";
import VerificationApprovalsLongCard from "../../components/Cards/VerificationApprovalsLongCard/VerificationApprovalsLongCard";
import { useNavigate, useLocation } from "react-router-dom";
import TransactionDetailsPage from "../TransactionDetailsPage/TransactionDetailsPage";
import BulkVerificationApprovalModal from "../../common/BulkVerificationApprovalModal/BulkVerificationApprovalModal";
import CfLoader from "../../common/CfLoader/CfLoader";

const VerificationApprovals = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  
  // Initialize state with saved filters from location state
  const savedFilters = location.state?.filters || {};
  
  const [limit, setLimit] = useState(6);
  const [respContentList, setRespContentList] = useState([]);
  const [count, setCount] = useState(0);
  const [previous, setPrevious] = useState(null);
  const [next, setNext] = useState(null);
  const [search, setSearch] = useState(savedFilters.search || "");
  const [isSearching, setIsSearching] = useState(false);
  const [isSearched, setIsSearched] = useState(false);
  const [offset, setOffset] = useState(savedFilters.offset || 0);
  const [selectedAmountStatus, setSelectedAmountStatus] = useState(savedFilters.amountStatus || null);
  const [selectedApprovalStatus, setSelectedApprovalStatus] = useState(savedFilters.approvalStatus || null);
  const [selectedVerifierOrganization, setSelectedVerifierOrganization] = useState(savedFilters.verifierOrg || null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [actionType, setActionType] = useState("");
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [verifierOrganizations, setVerifierOrganizations] = useState([
    { name: "Verifier Organization", value: null }
  ]);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const amountStatusOptions = [
    { name: "Payment Status", value: null },
    { name: "Paid", value: "Done" },
    { name: "Failed", value: "Failed" },
    { name: "Pending", value: "Pending" },
  ];

  const approvalStatusOptions = [
    { name: "Approval Status", value: null },
    { name: "Approved", value: "Valid" },
    { name: "Rejected", value: "Rejected" },
    { name: "Pending", value: "Approval Pending" },
  ];
 
  const updateAmountStatus = useCallback((value) => {
    if (!isInitialLoad) {
      setSelectedAmountStatus(value);
      setOffset(0);
    }
  }, [isInitialLoad]);

  const updateApprovalStatus = useCallback((value) => {
    if (!isInitialLoad) {
      setSelectedApprovalStatus(value);
      setOffset(0);
    }
  }, [isInitialLoad]);

  const updateVerifierOrganization = useCallback((value) => {
    if (!isInitialLoad) {
      setSelectedVerifierOrganization(value);
      setOffset(0);
    }
  }, [isInitialLoad]);

  const handleRemoveTransaction = (transaction_id) => {
    setRespContentList((prevList) =>
      prevList.filter(
        (transaction) => transaction.transaction_id !== transaction_id
      )
    );
  };

  const handleCheckboxChange = (transactionId, isChecked) => {
    setSelectedTransactions((prevSelected) => {
      if (isChecked) {
        const transaction = respContentList.find(
          (t) => t.transaction_id === transactionId
        );
        return [...prevSelected, transaction];
      } else {
        return prevSelected.filter((t) => t.transaction_id !== transactionId);
      }
    });
  };

  const handleHeaderCheckboxChange = () => {
    if (allSelected) {
      setSelectedTransactions((prevSelected) =>
        prevSelected.filter(
          (t) => !respContentList.some((item) => item.transaction_id === t.transaction_id)
        )
      ); // Remove transactions of this page
    } else {
      setSelectedTransactions((prevSelected) => [
        ...prevSelected,
        ...respContentList.filter(
          (item) => !prevSelected.some((t) => t.transaction_id === item.transaction_id)
        ),
      ]); // Add transactions of this page
    }
    setAllSelected(!allSelected); // Toggle header checkbox state
  };

  const handleBulkReject = () => {
    if (selectedTransactions.length > 0) {
      setActionType("Rejected");
      setIsModalOpen(true);
    }
  };

  const handleBulkAccept = () => {
    if (selectedTransactions.length > 0) {
      setActionType("Valid");
      setIsModalOpen(true);
    }
  };
 
  const handleConfirmAction = async (actionType, transaction_id, rejectionReason = "") => {
    if (selectedTransactions.length > 0 || transaction_id !== undefined) {
      // Send POST request to backend to process the action (Accept/Reject)
      const body = {
        transaction_id:
          selectedTransactions.length > 0
            ? selectedTransactions.map((transaction) => transaction.transaction_id)
            : transaction_id, // Fallback to transaction_id if no selected transactions
        verification_approval_status: actionType,
        ...(actionType === "Rejected" &&{ rejection_reason: rejectionReason?.trim() || ""}) 
      };
      console.log("Final Payload Sent:", body);
      try {
        const response = await POST(
          EntityConstants.GET_VERIFICATION_APPROVALS_LIST,
          body
        );

        if (response.ok) {
          GET_VERIFICATION_APPROVALS_LIST(limit, offset);
        }
      } catch (error) {
        console.error("Error during the POST request:", error);
      }

      // Clear selected transactions
      setSelectedTransactions([]);
      setAllSelected(false);
    }

    // Close modal
    setIsModalOpen(false);
  };

  const handleCardClick = (transaction) => {
    const currentFilters = {
      search,
      offset,
      amountStatus: selectedAmountStatus,
      approvalStatus: selectedApprovalStatus,
      verifierOrg: selectedVerifierOrganization
    };
    const status = selectedApprovalStatus || transaction.verification_approval_status || "Approval Pending";

    navigate(`/issuer/approvals/${transaction.transaction_id}`, {
      state: {
        ...transaction,
        filters: currentFilters,
        status,
      },
    });
  };

  const fetchVerifierOrganizations = async () => {
    try {
      const response = await GET(EntityConstants.GET_VERIFIER_ORGANIZATIONS_LIST);
  
      const data = await response.json();
  
      if (data?.data) {
        setVerifierOrganizations([
          { name: "Verifier Organization", value: null }, // Default option
          ...data.data.map((org) => ({
            name: org.org_name,
            value: org.org_code,
          })),
        ]);
      }
    } catch (error) {
      console.error("Error fetching Verifier Organizations:", error);
    }
  };

  const GET_VERIFICATION_APPROVALS_LIST = (limit, currentOffset) => {
    setIsSearching(true);
    let apiUrl = `${EntityConstants.GET_VERIFICATION_APPROVALS_LIST}?limit=${limit}&offset=${currentOffset}`;

    if (selectedVerifierOrganization) {
      apiUrl += `&verifier_organization=${selectedVerifierOrganization}`;
    }
    if (selectedAmountStatus) {
      apiUrl += `&payment_status=${selectedAmountStatus}`;
    }
    if (selectedApprovalStatus) {
      apiUrl += `&verification_approval_status=${selectedApprovalStatus}`;
    }
    if (search) {
      apiUrl += `&search=${search}`;
    }
    const temp_array = respContentList;
    setRespContentList([]);
    setAllSelected(false); 
    
    GET(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          Toast({
            type: "error",
            title: "Error",
            message: data.error,
          });
          setRespContentList(temp_array);
        } else {
          setCount(data.count);
          setRespContentList(data.results);
          setPrevious(data.previous);
          setNext(data.next);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsSearching(false);
        setIsSearched(true);
      });
  };

  useEffect(() => {
    if (savedFilters.offset) {
      const savedOffset = parseInt(savedFilters.offset);
      setOffset(savedOffset);
    }
    fetchVerifierOrganizations();
  }, []);

  useEffect(() => {
    GET_VERIFICATION_APPROVALS_LIST(limit, offset);
    // After the first API call, set isInitialLoad to false
    if (isInitialLoad) {
      setIsInitialLoad(false);
    }
  }, [
    search,
    selectedAmountStatus,
    selectedApprovalStatus,
    selectedVerifierOrganization,
    limit,
    offset
  ]);

  const handlePrevious = () => {
    setOffset(offset - limit);
  };

  const handleNext = () => {
    setOffset(offset + limit);
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col pt-20 pb-10">
      <div className="flex flex-wrap items-center gap-4 justify-between mx-[1rem] mt-[0.6rem] w-full">
        <div className="text-xl font-semibold text-black ml-[1rem] w-full md:w-auto">
          Verification Approvals
        </div>

        <div>
          {selectedTransactions.length > 0 && (        
            <div className="flex gap-4 w-full md:w-auto justify-end md:justify-normal mr-auto pr-12">
              <button
                className="flex rounded-[8px] lg:rounded-xl items-center justify-center gap-2 w-[10rem] h-[2.5rem] lg:w-[10rem] lg:px-0 bg-cfPrimaryButton text-white font-medium lg:font-semibold text-sm lg:text-md"
                onClick={handleBulkAccept}
              >
                <div>Approve</div>
              </button>

              <button
                className="flex rounded-[8px] lg:rounded-xl items-center justify-center gap-2 w-[10rem] h-[2.5rem] lg:w-[10rem] lg:px-0 bg-white text-black font-medium lg:font-semibold text-sm lg:text-md border border-border-[#000000]"
                onClick={handleBulkReject}
              >
                <div>Reject</div>
              </button>
            </div>
          )}

          <BulkVerificationApprovalModal
            isModalOpen={isModalOpen}
            toggleModal={setIsModalOpen}
            actionType={actionType}
            handleConfirmAction={handleConfirmAction}
            selectedTransactions={selectedTransactions}
            setRejectionReason={setRejectionReason}
          />
        </div>

        <div className="flex items-center gap-4 ml-[1rem] w-full">
          <div className="flex gap-4 w-full">
            <Dropdown
              options={verifierOrganizations}
              selected={
                verifierOrganizations.find(
                  (option) => option.value === selectedVerifierOrganization
                ) || verifierOrganizations[0]
              }
              onSelect={updateVerifierOrganization}
              buttonClass="px-4 py-2 border rounded-md"
              menuClass="z-20"
            />
            <Dropdown
              options={amountStatusOptions}
              selected={
                amountStatusOptions.find(
                  (option) => option.value === selectedAmountStatus
                ) || amountStatusOptions[0]
              }
              onSelect={updateAmountStatus}
              buttonClass="px-4 py-2 border rounded-md"
              menuClass="z-20"
            />
            <Dropdown
              options={approvalStatusOptions}
              selected={
                approvalStatusOptions.find(
                  (option) => option.value === selectedApprovalStatus
                ) || approvalStatusOptions[0]
              }
              onSelect={updateApprovalStatus}
              buttonClass="px-4 py-2 border rounded-md"
              menuClass="z-20"
            />
          </div>

          <div className="ml-auto mr-[3rem]">
            <form
              onSubmit={(e) => e.preventDefault()}
              className="bg-cfSecondary p-2 rounded-lg w-full lg-xl:w-[25rem] self-center md:self-end md:m-0 border mt-1 flex justify-end"
            >
              <input
                type="text"
                name="search"
                id="search"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value.toLowerCase());
                  setOffset(0);
                }}
                placeholder="Search for organization name, transaction ID"
                className="outline-none border-none p-0 placeholder:text-sm w-full"
                style={{ fontSize: "0.875rem", lineHeight: "1.25rem" }}
              />
            </form>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto">
        <div className="flex flex-col justify-center gap-2 min-w-[73.5rem] md:w-full px-8">
          <div className="flex bg-[#E0EAFF] items-center min-h-[5rem] border py-4 px-6 mt-6 ml-[0.05rem] mr-[0.05rem]">
            <div className="flex w-[2.0%] justify-center items-center">
             {selectedApprovalStatus !== "Valid" && selectedApprovalStatus !== "Rejected" && (
              <input
                type="checkbox"
                checked={allSelected}
                onChange={handleHeaderCheckboxChange}
                className="cursor-pointer"
              />
             )}
            </div>
            <div className="flex w-[14.29%] justify-center items-center text-[#344054] font-bold text-[14px]">
            Verifier Name
          </div>
          <div className="flex w-[14.29%] justify-center text-[#344054] font-bold text-[14px]">
            Verifier Organization
          </div>
          <div className="flex w-[14.29%] justify-center text-[#344054] font-bold text-[14px]">
            Created At
          </div>
          <div className="flex w-[14.29%] justify-center text-[#344054] font-bold text-[14px]">
            Credissuer Verified
          </div>
          <div className="flex w-[14.29%] justify-center text-[#344054] font-bold text-[14px]">
            Amount Paid
          </div>
          <div className="flex w-[14.29%] justify-center text-[#344054] font-bold text-[14px]">
            Document Type
          </div>
          <div className="flex w-[14.29%] justify-center text-[#344054] font-bold text-[14px]">
            Actions
          </div>
          </div>

          {isSearching && <CfLoader />} 
          {!isSearching && isSearched && respContentList.length > 0 && (
            respContentList.map((transaction) => (
              <VerificationApprovalsLongCard
                key={transaction.transaction_id}
                {...transaction}
                onCheckboxChange={handleCheckboxChange}
                isChecked={selectedTransactions.some(
                  (t) => t.transaction_id === transaction.transaction_id
                )}
                handleConfirmAction={handleConfirmAction}
                selectedTransactions={selectedTransactions}
                onRemoveTransaction={handleRemoveTransaction}
                onClick={() => handleCardClick(transaction)}
                selectedApprovalStatus={selectedApprovalStatus}
                setRejectionReason={setRejectionReason}
              />
            ))
          )}
          {!isSearching && isSearched && respContentList.length === 0 && (
            <p className="text-center">No results found</p>
          )}
        </div>
      </div>

      <div className="flex items-center justify-between mt-6 px-8 w-full">
        <button
          onClick={handlePrevious}
          className="flex items-center justify-center px-5 py-2 w-36 text-sm active:text-gray-700 capitalize transition-colors duration-200 bg-cfSecondary border rounded-md gap-x-2 hover:bg-gray-100 disabled:text-gray-400"
          disabled={previous === null}
        >
          <span className="flex items-center justify-center gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-5 h-5 rtl:-scale-x-100"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
              />
            </svg>

            <span>previous</span>
          </span>
        </button>

        <button
          onClick={handleNext}
          className="flex items-center justify-center px-5 py-2 w-36 text-sm active:text-gray-700 capitalize transition-colors duration-200 bg-cfSecondary border rounded-md gap-x-2 hover:bg-gray-100 disabled:text-gray-400"
          disabled={next === null}
        >
          <span className="flex items-center justify-center gap-2">
            <span>Next</span>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-5 h-5 rtl:-scale-x-100"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
              />
            </svg>
          </span>
        </button>
      </div>
    </div>
  );
};

export default VerificationApprovals;
