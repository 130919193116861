import React, { useState, useRef, useEffect } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import Select from "react-select";
import EntityConstants from "../../configs/EntityConstants";
import { GET, POST, PUT } from "../../service/CRUDService";
import Toast from "../../common/Toast/Toast";
import CorrectIcon from "../../resources/icons/correct-green.svg";
import SimpleModal from "../../common/SimpleModal/SimpleModal";
import WarningIcon from "../../resources/icons/blue-warning.svg";

const EmailTemplateEditor = () => {
  const [emailSubject, setEmailSubject] = useState("");
  const [editorContent, setEditorContent] = useState("");
  const [initialSubject, setInitialSubject] = useState("");
  const [initialContent, setInitialContent] = useState("");
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const sunEditorRef = useRef(null);

  const toolbarOptions = [
    ["font", "fontSize", "formatBlock"],
    ["bold", "italic", "underline", "strike", "subscript", "superscript"],
    ["fontColor", "hiliteColor"],
    ["removeFormat"],
    ["paragraphStyle"],
    ["align", "list", "horizontalRule", "lineHeight"],
    ["outdent", "indent"],
    ["image", "video", "blockquote", "codeView"],
    ["table", "link", "image"],
    ["undo", "redo"],
  ];

  const handleSubjectChange = (e) => {
    setEmailSubject(e.target.value);
  };

  const handleEditorChange = (content) => {
    setEditorContent(content);
  };

  const saveContent = async () => {
    if (emailSubject !== initialSubject || editorContent !== initialContent) {
      const response = await POST(EntityConstants.EMAIL_TEMPLATES, {
        template_id: selectedTemplate.template_id,
        email_subject: emailSubject,
        email_body: editorContent,
      });
      if (response.ok) {
        const data = await response.json();
        setInitialSubject(emailSubject);
        setInitialContent(editorContent);
        Toast({
          type: "success",
          title: "Success",
          message: data.message,
        });
      } else {
        const errorData = await response.json();
        Toast({
          type: "error",
          title: "Error",
          message: errorData.error,
        });
      }
    } else {
      console.log("No changes to save");
    }
  };

  const insertVariable = (variable) => {
    const placeholder = `{{${variable}}}`;
    if (sunEditorRef.current) sunEditorRef.current.insertHTML(placeholder);
  };

  const getSunEditorInstance = (sunEditor) => {
    sunEditorRef.current = sunEditor;
  };

  const fetchEmailTemplate = async () => {
    try {
      const emailTemplatesResponse = await GET(EntityConstants.EMAIL_TEMPLATES);
      const data = await emailTemplatesResponse.json();
      if (data && data.email_templates) {
        setEmailTemplates(data.email_templates);
        const activeTemplate = data.email_templates.find(
          (template) => template.is_active
        );
        if (activeTemplate) {
          setSelectedTemplate(activeTemplate);
          const htmlResponse = await fetch(activeTemplate.html_file_path);
          if (htmlResponse.ok) {
            const blob = await htmlResponse.blob();
            const htmlContent = await blob.text();

            setEmailSubject(activeTemplate.email_subject);
            setInitialSubject(activeTemplate.email_subject);
            if (sunEditorRef.current) {
              sunEditorRef.current.setContents(htmlContent);
              setEditorContent(htmlContent);
              setInitialContent(htmlContent);
            }
          } else {
            Toast({
              type: "error",
              title: "Error",
              message: "Failed to fetch HTML content from S3 path",
            });
          }
        }
      } else {
        Toast({
          type: "error",
          title: "Error",
          message: "Error in fetching the data",
        });
      }
    } catch (error) {
      console.error("Error fetching email template:", error);
      Toast({
        type: "error",
        title: "Error",
        message: "Error fetching email template",
      });
    }
  };

  const handleTemplateChange = async (selectedOption) => {
    const template = emailTemplates.find(
      (t) => t.template_id === selectedOption.value
    );
    setSelectedTemplate(template);

    if (template) {
      setEmailSubject(template.email_subject);

      try {
        const htmlResponse = await fetch(template.html_file_path);
        if (htmlResponse.ok) {
          const blob = await htmlResponse.blob();
          const htmlContent = await blob.text();

          if (sunEditorRef.current) {
            sunEditorRef.current.setContents(htmlContent);
            setEditorContent(htmlContent);
          }
        } else {
          Toast({
            type: "error",
            title: "Error",
            message: "Failed to fetch email template content",
          });
        }
      } catch (error) {
        console.error("Error fetching email template content:", error);
        Toast({
          type: "error",
          title: "Error",
          message: "Error fetching email template content",
        });
      }
    }
  };

  const activateTemplate = async () => {
    if (selectedTemplate) {
      const response = await PUT(EntityConstants.EMAIL_TEMPLATES, {
        template_id: selectedTemplate.template_id,
        is_active: true,
      });

      if (response.ok) {
        setIsModalOpen(false);
        const data = await response.json();

        setEmailSubject(data.template.email_subject);
        setInitialSubject(data.template.email_subject);

        try {
          const htmlResponse = await fetch(data.template.html_file_path);
          if (htmlResponse.ok) {
            const blob = await htmlResponse.blob();
            const htmlContent = await blob.text();

            if (sunEditorRef.current) {
              sunEditorRef.current.setContents(htmlContent);
              setEditorContent(htmlContent);
              setInitialContent(htmlContent);
            }
          } else {
            Toast({
              type: "error",
              title: "Error",
              message: "Failed to fetch updated HTML content",
            });
          }
        } catch (error) {
          console.error("Error fetching updated email content:", error);
          Toast({
            type: "error",
            title: "Error",
            message: "Error fetching updated email content",
          });
        }

        Toast({
          type: "success",
          title: "Success",
          message: data.message,
        });
        fetchEmailTemplate();
      } else {
        const errorData = await response.json();
        Toast({
          type: "error",
          title: "Error",
          message: errorData.error,
        });
      }
    }
  };

  useEffect(() => {
    fetchEmailTemplate();
  }, []);

  useEffect(() => {
    const customDropdownButton = document.createElement("select");
    customDropdownButton.innerHTML = ` 
      <option value="">Variables</option>
      <option value="first_name">First Name</option>
      <option value="last_name">Last Name</option>
      <option value="org_name">Organization Name</option>
    `;
    customDropdownButton.style.cssText =
      "width: 100px; margin-left: 5px; margin-bottom: 3px; padding: 5px 10px; font-size: 14px;";

    customDropdownButton.addEventListener("change", (e) => {
      const selectedValue = e.target.value;
      if (selectedValue) {
        insertVariable(selectedValue);
      }
    });

    const toolbarElement = document.querySelector(".se-toolbar");
    if (toolbarElement) {
      toolbarElement.appendChild(customDropdownButton);
    }

    return () => {
      if (toolbarElement) {
        toolbarElement.removeChild(customDropdownButton);
      }
    };
  }, []);

  useEffect(() => {
    if (emailSubject !== initialSubject || editorContent !== initialContent) {
      setIsDataChanged(true);
    } else {
      setIsDataChanged(false);
    }
  }, [emailSubject, editorContent, initialSubject, initialContent]);

  const customStyles = {
    menuPortal: (base) => ({
      ...base,
      zIndex: 9,
    }),
    option: (provided, { data }) => ({
      ...provided,
      backgroundColor: data.is_active ? "white" : "white",
      color: data.is_active ? "black" : "black",
    }),
  };

  const templateOptions = emailTemplates.map((template) => ({
    value: template.template_id,
    label: (
      <div className="flex items-center">
        {template.template_name}{" "}
        {template.is_active && (
          <div className="flex ml-2 px-[12px] py-[2px] bg-[#E1FCD9] text-[#358F14] rounded-[99px] border border-[#C8EBBC] items-center">
            <img src={CorrectIcon} className="mr-1 h-4 w-4" alt="active" />
            Active
          </div>
        )}
      </div>
    ),
    is_active: template.is_active,
  }));

  return (
    <div className="mx-auto p-3">
      <div className="mx-auto p-3 border border-gray-300 rounded-md">
        <div className="text-xl font-semibold text-gray-900">
          <p>Email Template Editor</p>
        </div>
        <div className="flex items-center gap-4 mt-4 mb-4">
          <div className="flex-1">
            <label
              className="text-sm font-medium text-gray-900"
              htmlFor="email-template"
            >
              Email Template
            </label>
            <Select
              options={templateOptions}
              styles={customStyles}
              onChange={handleTemplateChange}
              value={templateOptions.find(
                (option) => option.value === selectedTemplate?.template_id
              )}
              menuPortalTarget={document.body}
            />
          </div>

          {selectedTemplate && !selectedTemplate.is_active && (
            <div className="self-end">
              <button
                className="px-6 py-[0.6rem] bg-cfPrimaryButton text-white font-semibold text-base font-CfPrimaryFont rounded-md border-none transition-all"
                onClick={() => setIsModalOpen(true)}
              >
                Use Template
              </button>
            </div>
          )}
          {selectedTemplate && selectedTemplate.is_active && (
            <div className="self-end">
              <button className="px-6 py-[0.6rem] cursor-not-allowed focus:outline-none bg-[#868688] text-white font-semibold text-base font-CfPrimaryFont rounded-md border-none transition-all">
                Active Template
              </button>
            </div>
          )}
        </div>

        {/* Email Subject */}
        <div className="email-subject mt-4 mb-4">
          <label
            className="text-sm font-medium text-gray-900"
            htmlFor="email-subject"
          >
            Email Subject
          </label>
          <input
            className="w-full p-2 text-base text-gray-700 rounded-md border border-gray-300"
            type="text"
            id="email-subject"
            value={emailSubject}
            onChange={handleSubjectChange}
            placeholder="Enter email subject"
          />
        </div>

        {/* Email Body */}
        <div className="email-body mb-5">
          <label
            className="text-sm font-medium text-gray-900"
            htmlFor="email-body"
          >
            Email Body
          </label>
          <SunEditor
            getSunEditorInstance={getSunEditorInstance}
            setOptions={{
              buttonList: toolbarOptions,
              height: "50vh",
              attributesWhitelist: {
                all: "class|style|data-.+",
              },
              align: "left|center|right",
            }}
            onChange={handleEditorChange}
            value={editorContent}
          />
        </div>

        {/* Save Button */}
        <button
          disabled={!isDataChanged}
          className={`relative inline-flex items-center justify-start px-6 md:px-12 py-2 overflow-hidden font-medium ${
            isDataChanged ? "bg-cfPrimary" : "bg-gray-400"
          } text-white rounded-md border-none transition-all`}
          onClick={saveContent}
        >
          Save Changes
        </button>
      </div>

      <SimpleModal
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        successFunction={activateTemplate}
        modalIcon={WarningIcon}
        leftButtonMessage={"Cancel"}
        rightButtonMessage={"Confirm"}
        modalHeaderMessage={"Confirm Template Activation"}
        modalBodyMessage={`This template will be used for sending notifications to
        the recipients of the certificates.`}
      />
    </div>
  );
};

export default EmailTemplateEditor;
