const OPEN_URL = "api/";
const AUTH_URL = "";

const EntityConstants = {
  CSRF_TOKEN: `${OPEN_URL}users/signup/`,
  SIGNUPHOLDER: `${OPEN_URL}users/signup/holder`,
  SIGNUPISSUER: `${OPEN_URL}users/signup/issuer`,
  LOGIN: `${OPEN_URL}users/login/`,
  LOGOUT: `${OPEN_URL}users/logout/`,
  CURRENT_USER: `${OPEN_URL}users/me/`,
  ONBOARDING_APPLY: `${OPEN_URL}holder/claim/process`,
  CREDENTIALS: `${OPEN_URL}credentials/received`,
  DOWNLOAD_CREDENTIALS: `${OPEN_URL}credentials/presentation`,
  WORKFLOW: `${OPEN_URL}workflow/get-workflows/?tenant_id=`,
  DASH_APPLICATION: `${OPEN_URL}holderclaimprocess/list/?holder_id=`,
  FORMS: `${OPEN_URL}workflow/get-workflowsteps/?workflow_id=`,
  CONTINUE_FORM: `${OPEN_URL}claim/details/?holder_claim_process_id=`,
  APPLICATIONS: `${OPEN_URL}audit/applications/list`,
  APPLICATION_DETAIL: `${OPEN_URL}applications/details`,
  APPLICATION_REVIEW: `${OPEN_URL}audit/applications/review`,
  APPLICATION_REVIEW_SUBMIT: `${OPEN_URL}audit/applications/review`,
  APPLICATION_SUMMARY: `${OPEN_URL}onboarding/applications-summary`,
  APPLICATIONS_REVIEWED: `${OPEN_URL}audit/applications/reviewed`,
  AUDIT_APPLICATION_DETAIL: `${OPEN_URL}audit/applications/formdata`,
  AUDIT_CREDENTIAL_DETAIL: `${OPEN_URL}credentials/issued`,

  DISCOVERY_SEARCH: `${OPEN_URL}credentials/search?search=`,
  DISCOVERY_TAG: `${OPEN_URL}credentials/tags`,

  DISCOVERY_TAG_SEARCH: `${OPEN_URL}credentials/templates?`,
  DISCOVERY_TEMPLATE: `${OPEN_URL}credentials/categories/templates?`,
  CREDENTIAL_TEMPLATE_DETAILS: `${OPEN_URL}credentials/templates/`,
  CREDENTIAL_TEMPLATE_ONBOARDING_DETAILS: `${OPEN_URL}workflow/get-workflowsteps/`,

  GET_CREDENTIALS_TEMPLATE_DESIGN: `${OPEN_URL}credentials/`,
  POST_CREDENTIALS_TEMPLATE_DESIGN: `${OPEN_URL}credentials/`,

  GET_CREDENTIALS_SUBJECT_FORM: `${OPEN_URL}credentials/`,

  POST_CREDENTIALS_SINGLE_ISSUANCE: `${OPEN_URL}credentials/issue/single?credential_template=`,
  POST_CREDENTIALS_BULK_ISSUANCE: `${OPEN_URL}credentials/issue/bulk?credential_template=`,

  GET_USER_PREVIEW_SVG: `${OPEN_URL}credentials/template/issuer`,

  GET_USER_PRESENTATION_SVG: `${OPEN_URL}credentials/presentation`,
  GET_USER_BULK_TRANSACTION_DETAILS: `${OPEN_URL}credentials/issued`,
  ACTIVATE_ACCOUNT: `${OPEN_URL}users/activate`,
  GET_TRANSACTIONS_DETAILS: `${OPEN_URL}credentials/v2/transactions/`,
  GET_TRANSACTIONS_INDIVIDUAL_DETAILS: `${OPEN_URL}credentials/transactions`,

  GET_ISSUER_DRAFTS: `${OPEN_URL}credentials/templates/issuer/`,
  GET_DRAFT_CERTIFICATE_DATA: `${OPEN_URL}credentials/template/issuer?issuer_credential_template_id=`,
  // GET_DRAFT_CERTIFICATE_DATA: `${OPEN_URL}credentials/templates/issuer/`,

  G_LOGIN: `${OPEN_URL}users/social-login/google`,
  MICROSOFT_LOGIN: `${OPEN_URL}users/social-login/microsoft`,
  MICROSOFT_AUTH_CODE:
    "https://login.microsoftonline.com/common/oauth2/v2.0/token",

  SEND_NOTIFICATION: `${OPEN_URL}notification/send`,

  GET_EMAIL_WHATSAPP_DEFAULT_DETAILS: `${OPEN_URL}credentials/`,

  GET_DEFAULT_EMAIL_WHATSAPP: `${OPEN_URL}notification/details?credential_id=`,

  DISCOVERY_DEFAULT_CATEGORY: `${OPEN_URL}credentials/categories`,

  TEMPLATE_PER_CATEGORY: `${OPEN_URL}credentials/categories/templates?`,

  GET_QR_IMAGE: `${OPEN_URL}users/settings/qr-code/logo`,

  POST_LOGO_FOR_QR: `${OPEN_URL}users/settings/qr-code/logo`,

  GET_ORG_DETAILS: `${OPEN_URL}users/settings/details`,

  POST_ORG_DETAILS: `${OPEN_URL}users/settings/details`,

  GET_DRAFTS_TEMPLATES: `${OPEN_URL}credentials/templates/issuer/?`,

  DOWNLOAD_SVG_AS_ZIP: `${OPEN_URL}credentials/download?transaction_id=`,

  DOWNLOAD_SVG_AS_PNG: `${OPEN_URL}credentials/download?credential_id=`,

  REVOKE_CREDENTIAL: `${OPEN_URL}credentials/revoke`,

  GET_CREDENTIALS_COUNTS: `${OPEN_URL}issuers/metrics/stats`,

  GET_CREDENTIALS_BAR_CHART: `${OPEN_URL}issuers/metrics/trendschart`,

  GET_CREDENTIALS_PIE_CHART_STATUS: `${OPEN_URL}issuers/metrics/statusdistribution`,

  GET_CREDENTIALS_ISSUERS_LIST: `${OPEN_URL}issuers/metrics/issuerslist`,

  GET_CREDENTIAL_JSON: `${OPEN_URL}credentials/credentials/`,

  GET_REFRESH_ACCESS_TOKEN: `${OPEN_URL}users/token/refresh/`,

  GET_ORGANIZATION_DETAILS: `${OPEN_URL}users/org/`,
  GET_ORGANIZATION_CERTIFICATES: `${OPEN_URL}users/org/offerings?`,

  GET_CERTIFICATE_PREVIEW: `${OPEN_URL}credentials/verify`,

  POST_FORGOT_PASSWORD: `${OPEN_URL}users/forgot-password/request`,
  POST_UPDATE_PASSWORD: `${OPEN_URL}users/update-password/update`,

  POST_TEST_EMAIL: `${OPEN_URL}users/email/identity/verify`,
  GET_EMAIL_DETAILS: `${OPEN_URL}users/email/identity`,
  POST_EMAIL_DETAILS: `${OPEN_URL}users/email/identity`,

  EMAIL_TEMPLATES: `${OPEN_URL}issuers/email/template`,

  PUT_REVOKE_DRAFT: `${OPEN_URL}credentials/template/issuer/update`,

  GET_PROGRAM_DETAILS: `${OPEN_URL}issuers/programme`,

  GET_VERIFICATION_APPROVALS_LIST: `${OPEN_URL}credentials/verification-approvals/`,
  
  GET_VERIFIER_ORGANIZATIONS_LIST: `${OPEN_URL}users/verifier-org/list`,
  
  POST_VERIFICATION_SETTINGS: `${OPEN_URL}issuers/verification-settings`,

  GET_PRE_ISSUANCE_HTML_PREVIEW: `${OPEN_URL}issuers/email/preview/pre-issuance`,

  GET_EMAIL_DETAILS_FOR_PREVIEW: `${OPEN_URL}issuers/email/metadata/pre-issuance`,
};
export default EntityConstants;
