import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import CrossIcon from "../../resources/icons/x-close.svg";
import CfLoader from "../CfLoader/CfLoader";
import { GET } from "../../service/CRUDService";
import EntityConstants from "../../configs/EntityConstants";
import JsonPrettifier from "../../service/PrettyPrintJSON";
import CopyToClipBoard from "../../utils/CopyToClipBoard";
import Copy from "../../resources/icons/copy.svg";
import Correct from "../../resources/icons/correct.svg";

const VerificationApprovalCertificateModal = (props) => {
  const [copied, setCopied] = useState(false);

  const ImageLoading = (
    <svg width="32rem" height="18.875rem" xmlns="http://www.w3.org/2000/svg">
      <rect width="100%" height="100%" fill="#f0f0f0" />
      <foreignObject
        x="91%"
        y="91%"
        width="200"
        height="200"
        style={{ transform: "translate(-50%, -50%)" }}
      >
        <div xmlns="http://www.w3.org/1999/xhtml">
          <CfLoader />
        </div>
      </foreignObject>
    </svg>
  );
  const { isOpen, certificateData, openCloseModal } = props;

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => openCloseModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 top-20 bg-black/30  backdrop-blur-sm " />
        </Transition.Child>

        <div className="fixed inset-0 top-20 overflow-y-auto px-4 md:px-0 max-h-full">
          <div className="flex items-center min-h-full justify-center text-center ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
               <Dialog.Panel className="w-full max-w-4xl  max-h-[95vh] transform overflow-x-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all overflow-y-auto">
                  <button
                    type="button"
                    className="absolute top-3 right-3 text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={() => openCloseModal(false)}
                  >
                    <span className="sr-only">Close</span>
                    &#10005;
                  </button>

              {/* <Dialog.Panel className="w-full max-w-3xl  max-h-[90vh] transform overflow-x-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all overflow-y-auto"> */}
              <div className="p-4 flex justify-center">
                 <img src={certificateData} alt="" className="w-full h-auto object-contain" />
              </div>
              
            </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default VerificationApprovalCertificateModal;
