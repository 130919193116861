import React, { useContext, useEffect, useState } from "react";
import { FileUpload } from "../../common/FileUpload/FileUpload";
import FormioSingleUpload from "../../common/FormioSingleUpload/FormioSingleUpload";
import ToggleButton from "../../common/ToggleButton/ToggleButton";

import ReactDOM from "react-dom";
import { ImAttachment } from "react-icons/im";
import { HiSquare3Stack3D } from "react-icons/hi2";
import { BsFillFileEarmarkBinaryFill } from "react-icons/bs";
import { GET, POST } from "../../service/CRUDService";
import EntityConstants from "../../configs/EntityConstants";
import AuthContext from "../../store/auth-context";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { LuImageOff } from "react-icons/lu";
import { RxReload } from "react-icons/rx";
import CfLoader from "../../common/CfLoader/CfLoader";
import { imgRegex } from "../../configs/App.Regex";
import GenerateCertificate from "../../service/GenerateCertificate";
import CfBreadcrumb from "../../common/CfBreadcrumb/CfBreadcrumb";
import { FaCertificate } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FiFileText } from "react-icons/fi";

const CertificateIssue = () => {
  const [isUploadMethod, setIsUploadMethod] = useState(false);

  const { credential_template_id, issuer_credential_template_id } = useParams();
  const [error, setError] = useState("");
  const [currentSVG, setCurrentSVG] = useState("");
  const [certificateData, setCertificateData] = useState({});
  const [fieldsChanged, setFieldsChanged] = useState({});
  const [fieldsData, setFieldsData] = useState({});

  const navigate = useNavigate();
  const location = useLocation();
  const authContext = useContext(AuthContext);

  const [breadcrumbItems, setBreadcrumbItems] = useState([]);

  const [transactionName, setTransactionName] = useState("");
  const [customFieldChanged, setCustomFieldChanged] = useState({});
  const [isToggleOn, setIsToggleOn] = useState(false);
  const [creditCount, setCreditCount] = useState(1);
  const [availableCredits, setAvailableCredits] = useState(0);
  const [isPostPaid, setIsPostPaid] = useState(true);

  const [currentPreview, setCurrentPreview] = useState("certificate-preview");

  const [htmlEmailPreview, setHtmlEmailPreview] = useState(null);
  const [fromEmail, setFromEmail] = useState(null);
  const [attachment, setAttachment] = useState(null);
  const [emailSubject, setEmailSubject] = useState(null);
  const [emailName, setEmailName] = useState(null);
  const [toEmail, setToEmail] = useState(null);

  const GET_CERTIFICATE_DATA = async (credential_template_id) => {
    GET(
      `${EntityConstants.GET_CREDENTIALS_SUBJECT_FORM}${credential_template_id}/subject?type=form`
    )
      .then((response) => response.json())
      .then((data) => {
        setCertificateData(data);
      })
      .catch((error) => {
        console.error("Error loading SVG:", error);
      });
  };

  const GET_USER_DETAILS = async () => {
    const response = await GET(EntityConstants.CURRENT_USER);
    const data = await response.json();
    if (response.ok) {
      if (
        data.mode_of_payment === "pre_paid" &&
        data.available_credits !== undefined
      ) {
        setAvailableCredits(data.available_credits);
        setIsPostPaid(false);
      } else if (data.mode_of_payment === "post_paid") {
        setIsPostPaid(true);
      } else {
        console.log("Invalid mode_of_payment or missing available_credits.");
      }
    } else {
      console.log("Failed to fetch user details.");
    }
  };

  const fetchHtmlPreview = async (fields) => {
    try {
      const response = await POST(
        `${EntityConstants.GET_PRE_ISSUANCE_HTML_PREVIEW}`,
        fields?.data
      );
      const data = await response.text();
      setHtmlEmailPreview(data);
    } catch (error) {
      console.log("Error fetching HTML preview :", error);
    }
  };
  const fetchEmailSubjectName = async () => {
    try {
      const emailTemplatesResponse = await GET(`${EntityConstants.GET_EMAIL_DETAILS_FOR_PREVIEW}/${issuer_credential_template_id}`);
      const data = await emailTemplatesResponse.json();
      const dataActive = data?.email_templates;
      setEmailSubject(dataActive.email_subject);
      setEmailName(dataActive.template_name);
      setFromEmail(dataActive.from_email);
      setAttachment(dataActive.attachment);
    } catch (error) {
      console.log("error while fetching email subject : ", error);
    }
  };

  const fetchDefaultSVG = async () => {
    try {
      const urlResp = await GET(
        `${EntityConstants.GET_USER_PREVIEW_SVG}?issuer_credential_template_id=${issuer_credential_template_id}`
      )
        .then((response) => response.json())
        .then((data) => {
          setTransactionName(data.certificate_name);
          const { template_svg } = data;
          fetch(`${template_svg}`).then(async (response) => {
            if (response.ok) {
              const svgContent = await response.text();
              setCurrentSVG(svgContent);
              //!injecting the svg content into the container
              const container = document.getElementById(
                "certificate_container"
              );
              container.style.filter =
                "drop-shadow(0 0 2px rgba(0, 0, 0, 0.264))";
              container.innerHTML = svgContent;
            } else if (response.status == 404) {
              const container = document.getElementById(
                "certificate_container"
              );
              ReactDOM.render(
                <div class="text-black flex justify-center w-full h-full text-lg">
                  <div className="flex flex-col items-center gap-2 w-fit h-fit">
                    <span className="flex items-center gap-2 w-fit h-fit  bg-gray-100 px-2 py-1 rounded-lg">
                      <p>Couldn't find image..</p>
                      <LuImageOff class="text-cfPrimaryCardHover " />
                    </span>
                    <span>
                      <p>Please create template again...</p>
                    </span>
                  </div>
                </div>,
                container
              );
            } else {
              const container = document.getElementById(
                "certificate_container"
              );
              ReactDOM.render(
                <div class="text-black flex justify-center w-full h-full text-lg">
                  <div
                    className="flex items-center gap-2 w-fit h-fit rounded-lg cursor-pointer"
                    onClick={() => {
                      ReactDOM.render(
                        <span className="w-full flex justify-center">
                          <CfLoader />
                        </span>,
                        container
                      );
                      fetchDefaultSVG();
                    }}
                  >
                    <p>Couldn't Load Image...</p>
                    <RxReload class="text-cfPrimaryCardHover " />
                  </div>
                </div>,
                container
              );
            }
          });
        });
      const urlContent = await urlResp.json();
      const { template_svg } = urlContent;
      const response = await fetch(`${template_svg}`);
      if (response.ok) {
        const svgContent = await response.text();
        setCurrentSVG(svgContent);
        //!injecting the svg content into the container
        const container = document.getElementById("certificate_container");
        container.style.filter = "drop-shadow(0 0 2px rgba(0, 0, 0, 0.264))";
        container.innerHTML = svgContent;
      } else if (response.status == 404) {
        const container = document.getElementById("certificate_container");
        ReactDOM.render(
          <div class="text-black flex justify-center w-full h-full text-lg">
            <div className="flex flex-col items-center gap-2 w-fit h-fit">
              <span className="flex items-center gap-2 w-fit h-fit  bg-gray-100 px-2 py-1 rounded-lg">
                <p>Coulden't find image..</p>
                <LuImageOff class="text-cfPrimaryCardHover " />
              </span>
              <span>
                <p>Please create template again...</p>
              </span>
            </div>
          </div>,
          container
        );
      } else {
        const container = document.getElementById("certificate_container");
        ReactDOM.render(
          <div class="text-black flex justify-center w-full h-full text-lg">
            <div
              className="flex items-center gap-2 w-fit h-fit rounded-lg cursor-pointer"
              onClick={() => {
                ReactDOM.render(
                  <span className="w-full flex justify-center">
                    <CfLoader />
                  </span>,
                  container
                );
                fetchDefaultSVG();
              }}
            >
              <p>Couldn't Load Image...</p>
              <RxReload class="text-cfPrimaryCardHover " />
            </div>
          </div>,
          container
        );
      }
    } catch (error) {
      console.error("Error loading SVG:", error);
    }
  };

  const formFieldsChanged = (fields) => {
    fields?.data?.recipientEmail
      ? setToEmail(fields?.data?.recipientEmail)
      : setToEmail(null);
      setFieldsData(fields);
    if (
      fields.changed?.component?.key !== undefined &&
      fields.changed?.value !== undefined
    ) {
      if (
        fields.changed?.value[0]?.type &&
        imgRegex.test(fields.changed?.value[0].url)
      ) {
        isToggleOn
          ? setFieldsChanged((prevData) => ({
              ...prevData,
              // Add your new key-value pair here
              [fields.changed?.component?.key]: fields.changed?.value[0].url,
            }))
          : setCustomFieldChanged((prevData) => ({
              ...prevData,
              // Add your new key-value pair here
              [fields.changed?.component?.key]: fields.changed?.value[0].url,
            }));
      } else {
        isToggleOn
          ? setFieldsChanged((prevData) => ({
              ...prevData,
              // Add your new key-value pair here
              [fields.changed?.component?.key]: fields.changed?.value,
            }))
          : setCustomFieldChanged((prevData) => ({
              ...prevData,
              // Add your new key-value pair here
              [fields.changed?.component?.key]: fields.changed?.value,
            }));
      }
    } else if (fields?.data) {
      setFieldsChanged(fields.data);
    }
  };

  const toggleUploadMethod = (bool) => {
    setIsUploadMethod(bool);
    if (!bool) {
      authContext.setRowCount(0);
      authContext.setFileUploaded(false);
      setCreditCount(1);
    } else {
      setCreditCount(authContext.rowCount);
    }
  };

  useEffect(() => {
    if (isUploadMethod) {
      const newCreditCount = authContext.rowCount || 0;
      if (availableCredits >= newCreditCount) {
        setCreditCount(newCreditCount);
      } else {
        setCreditCount("Insufficient Credits");
      }
    } else {
      const newCreditCount = 1;
      if (availableCredits >= newCreditCount) {
        setCreditCount(newCreditCount);
      } else {
        setCreditCount("Insufficient Credits");
      }
    }
  }, [authContext.rowCount, isUploadMethod, availableCredits]);

  useEffect(() => {
    if (currentSVG) {
      if (fieldsChanged) {
        const updatedFields = isToggleOn
          ? { ...fieldsChanged }
          : { ...customFieldChanged };
        const modifiedSVG = GenerateCertificate(currentSVG, updatedFields); // Use the service function
        document.getElementById("certificate_container").innerHTML =
          modifiedSVG;
      }
    }
  }, [
    fieldsChanged,
    customFieldChanged,
    htmlEmailPreview,
    currentSVG,
    isToggleOn,
  ]);

  useEffect(() => {
    GET_USER_DETAILS();
    fetchEmailSubjectName();
    GET_CERTIFICATE_DATA(credential_template_id).then(() => {
      fetchDefaultSVG();
    });
    const previousPath = authContext.lastVisitedPath;
    if (
      previousPath === "/issuer/drafts" ||
      sessionStorage.getItem("previousPath") === "/issuer/drafts"
    ) {
      setBreadcrumbItems([
        {
          title: "Receiver details",
          to: `/issuer/certificate/1/${credential_template_id}/${issuer_credential_template_id}`,
        },
      ]);
    } else {
      setBreadcrumbItems([
        { title: "Edit", to: `/certificate/0/${credential_template_id}` },
        {
          title: "Receiver details",
          to: `/issuer/certificate/1/${credential_template_id}/${issuer_credential_template_id}`,
        },
      ]);
    }
    authContext.lastVisitedPathHandler(location.pathname);
  }, []);

  useEffect(() => {

      currentPreview === "email-preview" && fieldsData && fetchHtmlPreview(fieldsData);
  }, [currentPreview, fieldsData, isToggleOn]);

  return (
    <div className="mt-20">
      {breadcrumbItems.length > 0 && (
        <CfBreadcrumb
          breadcrumbItems={breadcrumbItems}
          isToggleOn={isToggleOn}
          setIsToggleOn={setIsToggleOn}
          marginTop={"mt-[0rem]"}
          creditCount={creditCount}
          isPostPaid={isPostPaid}
        />
      )}
      <div className="flex flex-col sm:flex-row w-full">
        <div className="w-full md:w-[38%] max-h-[84vh] overflow-y-auto flex flex-col items-center flex-grow bg-cfSecondary min-h-full">
          <div className="h-auto w-full">
            <ToggleButton
              First_Text="Single Issuance"
              Second_Text="Batch Issuance"
              showText={true}
              LeftIcon={BsFillFileEarmarkBinaryFill}
              RightIcon={HiSquare3Stack3D}
              onToggle={toggleUploadMethod}
              UploadMethodBool={isUploadMethod}
            />
          </div>
          <div className="p-3 bg-cfSecondary h-full w-full">
            {isUploadMethod
              ? transactionName && (
                  <FileUpload
                    toggleUploadMethod={toggleUploadMethod}
                    transaction_name={transactionName}
                    creditCount={creditCount}
                    isPostPaid={isPostPaid}
                  />
                )
              : transactionName && (
                  <FormioSingleUpload
                    toggleUploadMethod={toggleUploadMethod}
                    URL={certificateData?.credential_subject_form}
                    formFieldsChanged={formFieldsChanged}
                    transaction_name={transactionName}
                    isToggleOn={isToggleOn}
                    creditCount={creditCount}
                    isPostPaid={isPostPaid}
                  />
                )}
          </div>
        </div>

        <div className="w-full mx-1 px-3 pt-3 overflow-x-auto min-w-[14rem]">
          <div className="flex w-fit rounded-lg self-center mx-auto mb-2 bg-cfSecondary h-10">
            <p
              onClick={(e) => {
                setCurrentPreview("certificate-preview");
              }}
              id="certificate-preview"
              className={`px-3 hover:text-cfPrimary flex items-center gap-2 ${
                currentPreview === "certificate-preview"
                  ? " border-b  border-b-cfPrimaryDark text-cfPrimaryDark shadow-[inset_0_-4px_5px_-5px] shadow-cfPrimaryLight"
                  : "border-transparent shadow-none cursor-pointer"
              } `}
            >
              <FaCertificate className="size-[25px]" />
              Certificate Preview
            </p>
            <p
              onClick={(e) => {
                setCurrentPreview("email-preview");
              }}
              id="email-preview"
              className={`px-3 hover:text-cfPrimary flex items-center gap-2 ${
                currentPreview === "email-preview"
                  ? " border-b  border-b-cfPrimaryDark text-cfPrimaryDark shadow-[inset_0_-4px_5px_-5px] shadow-cfPrimaryLight"
                  : "border-transparent shadow-none cursor-pointer"
              } `}
            >
              <MdEmail className="size-[25px]" />
              Email Preview
            </p>
          </div>
          <div className="w-full">
            <div
              className={` ${
                currentPreview === "certificate-preview" ? "flex" : "hidden"
              } flex-col flex-shrink  max-h-[73vh]`}
              id="certificate_container"
            >
              <CfLoader />
            </div>
            <div className="bg-transparent">
              {currentPreview === "email-preview" && (
                <div className="max-h-64 flex flex-col justify-evenly bg-cfSecondary sm:w-[37.5rem] mx-auto gap-3 border-b-[1.5px]">
                  <span className="flex px-14 border-b-[1.5px] py-3">
                    <div className="w-32 flex items-center gap-2">
                      <FiFileText className="size-5" />
                      <p className="w-32 font-semibold">Template:</p>
                    </div>
                    &nbsp;
                    <p>{emailName}</p>
                  </span>
                  <span className="flex mx-14">
                    <p className="w-32 font-semibold">From:</p>&nbsp;
                    <p>{fromEmail}</p>
                  </span>
                  <span className="flex mx-14">
                    <p className="w-32 font-semibold">To:</p>&nbsp;
                    <p>{toEmail}</p>
                  </span>
                  <span className="flex px-14">
                    <p className="w-32 font-semibold">Subject:</p>&nbsp;
                    <p>{emailSubject}</p>
                  </span>
                  {attachment && <span className="flex px-14 border-t-[1.5px] py-2">
                    <div className="w-[9.6rem] flex items-center">
                      <p className="flex items-center font-semibold gap-2">
                        {" "}
                        <ImAttachment /> Attachment
                      </p>
                      <p className="font-semibold text-gray-300 px-3">|</p>
                    </div>
                    <p className="border-[1.5px] rounded-[0.63rem] flex gap-2 items-center p-2 text-sm">
                      <FiFileText className="size-5 text-cfPrimaryDark" />
                      {attachment}
                    </p>
                  </span>}
                </div>
              )}
              <div
                className={` ${
                  currentPreview === "email-preview" ? "flex" : "hidden"
                } flex-col flex-shrink  max-h-[46vh] overflow-y-auto`}
                id="email-preview"
                dangerouslySetInnerHTML={{ __html: htmlEmailPreview }}
              >
                {/* <CfLoader /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CertificateIssue;
