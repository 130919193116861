import React, { useContext, useEffect, useRef, useState } from "react";
import Navbar from "../components/Navbar/Navbar";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import LoginModal from "../common/LoginModal/LoginModal";
import Sidebar from "../components/Sidebar/Sidebar";
import usePageBottom from "../hooks/usePageBottom";
import AuthContext from "../store/auth-context";
import ForgotPasswordModal from "../common/ForgotPasswordModal/ForgotPasswordModal";

const BaseLayout = () => {
  const authContext = useContext(AuthContext);
  const customElementRef = useRef(null);
  const reachedBottom = usePageBottom(
    { rootMargin: 0, threshold: 0.5 },
    customElementRef
  );
  const [signOrSignUp, setSignOrSignUp] = useState(true);

  const location = useLocation();
  const isPublicPage =
    location.pathname.includes("/organizations") ||
    location.pathname.includes("/users/update-password");
  const shouldShowSidebar = authContext.isLoggedIn || !isPublicPage;

  const [redirecturl , setRedirectUrl] = useState(''); // set
  const navigate = useNavigate();
  const [hasCheckedRedirect, setHasCheckedRedirect] = useState(false);
  const TriggerLoginModuleBtnFromModule = () => {
    authContext.setTriggerLoginModuleToggleValue(false);
  };
  const TriggerLoginModuleBtn = () => {
    authContext.setTriggerLoginModuleToggleValue(true);
  };
  const chooseSignUp_SignIn = (bool) => {
    setSignOrSignUp(bool);
  };


  useEffect(() => {
    authContext.hasDiscoveryReachedBottomHandler(reachedBottom);
  }, [reachedBottom]);

  const storedRedirect = localStorage.getItem("redirectAfterLogin")


  useEffect(() => {
    const authToken = localStorage.getItem("auth_token");
    const isAuthStateLoaded = authContext.isLoggedIn !== undefined; //  undefined means "not yet loaded"
  
    if (!isAuthStateLoaded) {
      return;
    }
  
    if (authContext.isLoggedIn && storedRedirect) {
      localStorage.removeItem("redirectAfterLogin");
      navigate(storedRedirect, { replace: true });
    } else if (!authContext.isLoggedIn && storedRedirect && !authToken) {
      chooseSignUp_SignIn(true);
      TriggerLoginModuleBtn();
    }
  }, [authContext.isLoggedIn, storedRedirect, navigate]);


  return (
    <React.Fragment>
      <div className="fixed top-0 w-full z-10">
        <Navbar
          TriggerLoginModuleBtn={TriggerLoginModuleBtn}
          chooseSignUp_SignIn={chooseSignUp_SignIn}
        />
      </div>
      <div className="overflow-y-hidden">
        {shouldShowSidebar && (
          <aside
            className="fixed top-[5.1rem] left-0 w-fit h-screen z-10"
            aria-label="Sidebar"
          >
            <div className="h-full overflow-y-auto">
              <Sidebar />
            </div>
          </aside>
        )}
        <div
          className={`transition-all ${
            shouldShowSidebar
              ? "mb-[4rem] sm:!mb-[0rem] ml-0 sm:!ml-[5.7rem]"
              : "ml-0"
          } mt-[5.1rem] overflow-auto`}
        >
          <Outlet />
        </div>
      </div>
      <LoginModal
        TriggerBtn={authContext.triggerLoginModuleToggleValue}
        TriggerModuleBtnFromModule={TriggerLoginModuleBtnFromModule}
        signOrSignUp={signOrSignUp}
        chooseSignUp_SignIn={chooseSignUp_SignIn}
        DisplayText=""
      />
      <ForgotPasswordModal
        isModalOpen={authContext.isForgotPassword}
        certificate_header="Reset Password"
        certificate_description="Enter your email to receive password reset instructions."
      />
    </React.Fragment>
  );
};

export default BaseLayout;
