import React, { useContext, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import SignInSideCurtains from "../../components/SignInSideCurtains/SignInSideCurtains";
import SignUpSideCurtains from "../../components/SignUpSideCurtains/SignUpSideCurtains";
import AuthContext from "../../store/auth-context";

import CloseSvg from "../../resources/icons/x-close.svg";
import { useAuthContext } from '../../store/auth-context';

function LoginModel({
  TriggerBtn,
  TriggerModuleBtnFromModule,
  signOrSignUp,
  chooseSignUp_SignIn,
  DisplayText,
}) {
  let [isOpen, setIsOpen] = useState(TriggerBtn);
  let [isMessage, setIsMessage] = useState(false);
  const [isSignUp, setIsSignUp] = useState();
  const authContext = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();

  function closeModal() {
    setIsOpen(false);
    TriggerModuleBtnFromModule();
  }
  function toggleComponent() {
    chooseSignUp_SignIn(!signOrSignUp);
  }
  useEffect(() => {
    setIsOpen(TriggerBtn);
  }, [TriggerBtn]);
  function toggleIsMessage() {
    setIsMessage((prev) => !prev);
  }
  useEffect(() => {
    signOrSignUp && setIsMessage(false);
    authContext.isSignUpHandler(signOrSignUp);
  }, [signOrSignUp]);

  // useEffect(()=>{
  //   signOrSignUp ? setIsSignUp(signOrSignUp) : setIsSignUp(true)
  //   console.log("signOrSignUp >> ",signOrSignUp)
  // },[signOrSignUp]);

  // useEffect(()=>{
  //   console.log("isSignUp >> ",isSignUp);
  // },[isSignUp]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const redirectPath = searchParams.get("redirect");
  
    if (redirectPath) {
      localStorage.setItem("redirectAfterLogin", redirectPath);
    }
  }, [location]);
  

  return (
    <div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative h-screen z-20"
          onClose={closeModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-200"
            leave="ease-in-out"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-auto ">
            <div className="flex min-h-full items-center text-center justify-center my-2">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-200 transform"
                leave="transition ease-in-out transform"
              >
                <Dialog.Panel className="flex m-auto rounded-[1.25rem] max-w-xl transform bg-cfSecondary text-left align-middle shadow-xl transition-all relative">
                  <div className=" absolute top-12 right-0 px-8">
                    <img
                      src={CloseSvg}
                      alt=""
                      className="size-6"
                      onClick={closeModal}
                      type="button"
                    />
                  </div>
                  <div className="mt-5">
                    {/* <Dialog.Title
                    as="h3"
                    className="text-4xl font-medium leading-6 text-cfSignInSignUpHeadingText px-8"
                  >
                    {isSignUp ? "Sign in" : !isMessage  && "Sign up"}
                  </Dialog.Title> */}

                    {/* <Sign In closeModal={closeModal} toggleComponent={toggleComponent} /> */}
                    {signOrSignUp ? (
                      <SignInSideCurtains
                        closeModal={closeModal}
                        toggleComponent={toggleComponent}
                      />
                    ) : (
                      <SignUpSideCurtains
                        closeModal={closeModal}
                        toggleComponent={toggleComponent}
                        toggleIsMessage={toggleIsMessage}
                      />
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
export default LoginModel;
